// Import package
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { getConfiguration } from "../../appRedux/actions/Configuration";

export default function EditProfile({ onSubmit, userParticipant }) {
  const [knowFrom, setKnowFrom] = useState([])
  const [provinces, setProvinces] = useState([])
  const [shirtSizes, setShirtSizes] = useState([])

  useEffect(() => {
    getConfiguration("know_from_where")
      .then(responseData => {
        setKnowFrom(responseData.value.split("\n"))
      })
    getConfiguration("provinces")
      .then(responseData => {
        setProvinces(responseData.value)
      })
    getConfiguration("shirt_sizes")
      .then(responseData => {
        setShirtSizes(responseData.value)
      })
  }, [])

  return (
    <Form
      layout="vertical"
      initialValues={{
        ...userParticipant,
        know_idn_from_where: knowFrom.includes(userParticipant.know_idn_from_where) ? userParticipant.know_idn_from_where : null,
      }}
      style={{ padding: 10 }}
      onFinish={(values) => {
        onSubmit({ ...values });
      }}
      scrollToFirstError={true}
    >
      <Form.Item
        name="name"
        label="Nama lengkap"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nama lengkap peserta!",
          },
        ]}
      >
        <Input placeholder="" disabled />
      </Form.Item>
      <Form.Item
        name="email"
        label="Alamat email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Mohon masukkan alamat email dengan benar",
          },
        ]}
      >
        <Input disabled placeholder="email@gmail.com" />
      </Form.Item>
      <Form.Item
        name="nickname"
        label="Nama Panggilan"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nama panggilan peserta!",
            pattern: "^[a-zA-Z0-9 ]*$"
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        name="whatsapp_number"
        label="Nomor whatsapp"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nomor whatsapp!",
          },
        ]}
      >
        <PhoneInput
          defaultCountry="ID"
          style={{ width: "100%" }}
          placeholder="081362778829"
        />
      </Form.Item>
      <Form.Item
        name="company"
        label="Perusahaan"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nama perusahaan!",
          },
        ]}
      >
        <Input placeholder="ID-Networkers (IDN.ID)" />
      </Form.Item>
      <Form.Item
        name="job_position"
        label="Jabatan / posisi di perusahaan"
        rules={[
          {
            required: true,
            message: "Mohon masukkan jabatan / posisi di perusahaan!",
          },
        ]}
      >
        <Input placeholder="Network Engineer" />
      </Form.Item>
      <Form.Item
        name="address"
        label="Alamat tempat tinggal"
        rules={[
          {
            required: true,
            message: "Mohon masukkan alamat tempat tinggal!",
          },
        ]}
      >
        <Input.TextArea placeholder="Jl. Anggrek Rosliana no 12A, RT 3/RW 9, Kemanggisan Slipi Palmerah, Jakarta Barat 11480" />
      </Form.Item>
      <Form.Item
        name="province"
        label="Provinsi"
        rules={[
          {
            required: true,
            message: "Mohon masukkan provinsi tempat tinggal!",
          },
        ]}
      >
        <Select
          placeholder="Pilih provinsi tempat tinggal"
          showSearch
          dropdownMatchSelectWidth={true}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={provinces.map(province => {
            return {
              value: province,
              label: province,
            }
          })}
        />
      </Form.Item>
      <Form.Item
        name="shirt_size"
        label="Ukuran kaos"
        rules={[
          {
            required: true,
            message: "Mohon masukkan ukuran kaos!",
          },
        ]}
      >
        <Select placeholder="Pilih ukuran kaos" options={shirtSizes} />
      </Form.Item>
      <Form.Item
        name="know_idn_from_where"
        label="Tahu ID-Networkers darimana?"
        rules={[
          {
            required: true,
            message: "Mohon masukkan tahu dari mana!",
          },
        ]}
      >
        <Select placeholder="Pilih">
          {knowFrom.map(item => (
            <Select.Option value={item}>{item}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <center>
          <Button size="large" type="primary" htmlType="submit">
            Simpan
          </Button>
        </center>
      </Form.Item>
    </Form>
  );
}
