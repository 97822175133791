import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Radio, Upload } from "antd";
import { createTrainingMaterial, deleteTrainingMaterialPdf, getTrainingMaterialUploadUrl } from "../../../../appRedux/actions/TrainingMaterial";
import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function TrainingOptionMaterialForm({ trainingOption }) {
  const [form] = Form.useForm()
  const [pdfPath, setPdfPath] = useState(null)
  const history = useHistory()

  const uploadPdf = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const config = {
      headers: {
        "Content-Type": file.type,
        "x-ms-blob-type": "BlockBlob",
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    try {
      const { upload_url, blob_name, expired_at } = await getTrainingMaterialUploadUrl(trainingOption.id)
      if (!upload_url)
        return
      await axios.put(upload_url, file, config);
      onSuccess(blob_name);
      setPdfPath(blob_name)
    } catch (e) {
      onError({ e });
    }
  }

  const removePdf = (data) =>
    new Promise((resolve) => {
      deleteTrainingMaterialPdf({ blob_name: data.response }).then((data) => {
        resolve(data)
      })
    });

  function submit(data) {
    if (data.type == "pdf")
      data.path = pdfPath
    createTrainingMaterial({
      training_option_id: trainingOption.id,
      ...data
    }).then(() => {
      history.push("/training-option/" + trainingOption.id + "/material")
    })
  }

  return <Form
    layout="vertical"
    form={form}
    onFinish={submit}
  >
    <Form.Item
      name="name"
      rules={[{ required: true, message: "Mohon masukkan nama" }]}
      label="Nama"
    >
      <Input placeholder="Nama materi" />
    </Form.Item>
    <Form.Item
      name="type"
      rules={[{ required: true, message: "Mohon pilih tipe" }]}
      label="Tipe"
    >
      <Radio.Group>
        <Radio value={"pdf"}>PDF</Radio>
        <Radio value={"link"}>Link</Radio>
      </Radio.Group>
    </Form.Item>
    <Form.Item shouldUpdate noStyle>
      {() => form.getFieldValue("type") && (
        <>
          {form.getFieldValue("type") == "link" && (
            <Form.Item
              name="path"
              label={"Link"}
              rules={[
                { required: true, message: "Mohon masukkan url" },
                { type: "url", message: "Mohon masukkan url" }
              ]}
            >
              <Input placeholder="URL" />
            </Form.Item>
          )}
          {form.getFieldValue("type") == "pdf" && (
            <Form.Item
              name="path"
              label={"File PDF"}
              rules={[{ required: true, message: "Mohon unggah file pdf" }]}
            >
              <Upload
                maxCount={1}
                listType="picture"
                accept="application/pdf"
                customRequest={uploadPdf}
                onRemove={removePdf}
              >
                <Button icon={<UploadOutlined />}>Pilih file</Button>
              </Upload>
            </Form.Item>
          )}
        </>
      )}
    </Form.Item>
    <center>
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        icon={<SaveOutlined />}
      >
        Simpan
      </Button>
    </center>
  </Form>
}