import { Button, Form, Input, List, Modal, Typography } from 'antd'
import { Link, useHistory } from "react-router-dom"
import React, { useEffect, useState } from 'react'
import CircularProgress from '../../components/CircularProgress'
import PageContentWrapper from '../../components/PageContentWrapper'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { getConfiguration, updateConfiguration } from '../../appRedux/actions/Configuration'
import TextArea from 'antd/lib/input/TextArea'

export default function ActivatedAffiliateMessage() {
  const [body, setBody] = useState(null)

  const [form] = Form.useForm()

  const configurationName = "activated_affiliate_message"

  useEffect(() => {
    getConfiguration(configurationName)
      .then(data => {
        setBody(data.value)
      })
  }, [])

  return <>
    {body == null ?
      <CircularProgress />
      :
      <PageContentWrapper title="Edit Pesan Affiliator" backLink={"/configurations"}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            body: body,
          }}
          style={{ padding: 10 }}
          onFinish={(values) => {
            updateConfiguration({
              name: configurationName,
              value: values.body
            })
          }}
          onValuesChange={(changedValues, allValues) => {

          }}
        >
          <Form.Item
            label="Isi Pesan Affiliator"
            name="body"
            style={{ height: 300 }}
            rules={[{ required: true, message: "Mohon isikan peraturan Pesan Affiliator" }]}
          >
            {/* <TextArea /> */}
            <ReactQuill theme="snow" value={body} onChange={setBody} style={{ height: 240 }} />
          </Form.Item>
          <Form.Item>
            <center>
              <Button type="primary" htmlType="submit">
                Simpan
              </Button>
            </center>
          </Form.Item>
        </Form>
      </PageContentWrapper >
    }
  </>
}