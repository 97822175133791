import React from "react";
import { Space, Button, Dropdown, Menu } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useHistory } from "react-router-dom";

export default function TrainingOptionActions({
  onEdit,
  onDelete,
  onDownloadDummyCertificate,
  subpath,
  trainingOptionId,
}) {
  const screens = useBreakpoint();
  const history = useHistory()
  return (
    <Space>
      {subpath === "material" && <>
        <Button type="primary" style={{ margin: 0 }} onClick={() => { history.push("/training-option/" + trainingOptionId + "/material-add") }}>
          <PlusCircleOutlined /> Materi
        </Button>
      </>}
      <Button type="primary" style={{ margin: 0 }} onClick={onEdit}>
        Edit
      </Button>
      {screens.xs && (
        <Button
          type="danger"
          icon={<DeleteOutlined />}
          style={{ margin: 0 }}
          onClick={onDelete}
        >
          Hapus
        </Button>
      )}
      {!screens.xs && (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="0"
                icon={<DeleteOutlined />}
                danger
                onClick={onDelete}
              >
                Hapus
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={["click"]}
        >
          <i className="gx-icon-btn icon icon-ellipse-v" />
        </Dropdown>
      )}
    </Space>
  );
}
