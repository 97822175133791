import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTrainingOption } from '../../appRedux/actions';
import PageContentWrapper from '../../components/PageContentWrapper';
import CircularProgress from '../../components/CircularProgress';
import TrainingOptionMaterialForm from '../../containers/adminRole/trainingOption/TrainingOptionMaterialForm';

export default function EditTrainingOptionMaterialPage() {
  const dispatch = useDispatch();
  const { trainingOptionId, trainingMaterialId } = useParams();
  const { trainingOption, trainingOptionLoading } = useSelector(
    ({ trainingOption }) => trainingOption
  );
  useEffect(() => {
    dispatch(getTrainingOption(trainingOptionId));
  }, [dispatch]);

  return trainingOption && !trainingOptionLoading ? (
    <PageContentWrapper
      title={(trainingMaterialId ? "Edit " : "Tambah ") + "Materi Training untuk " + trainingOption.name}
      backLink={"/training-option/" + trainingOption.id + "/material"}
    >
      <TrainingOptionMaterialForm trainingOption={trainingOption} />
    </PageContentWrapper>
  ) : trainingOptionLoading ? (
    <CircularProgress />
  ) : (
    "tidak ditemukan"
  )
}
