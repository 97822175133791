import React from "react";
import { Table, Tag, Space } from "antd";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import TrainingRegistrationsListActionsContainer from "../../../../containers/adminRole/trainingRegistration/TrainingRegistrationsListActionsContainer";
const getColumns = ({ trainingRegistrations }) => [
  {
    title: "#",
    dataIndex: "id",
    align: "center",
    width: 1,
  },
  {
    title: "Nama",
    dataIndex: "participant",
    // width: 150,
    // ellipsis: true,
    render: (participant, item) => (
      <>
        <Link to={"/training-registration/" + item.id}>
          {participant.name.split(" ").length > 2
            ? participant.name.split(" ")[0] +
            " " +
            participant.name.split(" ")[1]
            : participant.name}
        </Link>
        <br />
        {participant.email}
      </>
    ),
  },
  {
    title: "Training",
    dataIndex: "training",
    responsive: ["sm"],
    render: (training, item) => (
      <>
        <Space>
          {training.training_option.name}
          {/* <br />*/}
          {!item.is_online ? (
            <Tag color="magenta" style={{ margin: 0 }}>
              {training.location}
            </Tag>
          ) : (
            <Tag color="blue" style={{ margin: 0 }}>
              online
            </Tag>
          )}
        </Space>
        <br />
        {training.dates_summary}
      </>
    ),
  },
  // {
  //   title: "Jenis",
  //   dataIndex: "is_retraining",
  //   responsive: ["sm"],
  //   align: "center",
  //   render: (isRetraining) => (isRetraining ? "Mengulang" : "Reguler"),
  // },
  {
    title: "Jenis",
    dataIndex: "type",
    responsive: ["sm"],
    align: "center",
    render: (type, item) => <>
      {type ? <>
        {type == "regular" ? "Reguler" : ""}
        {type == "retraining" ? "Mengulang" : ""}
        {type == "private" ? "Private" : ""}
        {type == "rescheduled" ? "Rescheduled" : ""}
      </> : <>
        {item.is_retraining ? "Mengulang" : "Reguler"}
      </>}
    </>,
  },
  {
    title: "Kode transfer",
    responsive: ["sm"],
    align: "center",
    dataIndex: "transfer_code",
    render: (transferCode, item) =>
      !item.is_retraining ? (transferCode ? transferCode : "-") : "-",
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    width: 10,
    responsive: ["sm"],
    render: (status, field) =>
      !field.is_retraining ? (
        <Tag
          icon={
            status === "paid_off" ? (
              <CheckCircleOutlined />
            ) : status === "canceled" ? (
              <CloseCircleOutlined />
            ) : (
              <ClockCircleOutlined />
            )
          }
          color={
            status === "paid_off"
              ? "success"
              : status === "canceled"
                ? "error"
                : "default"
          }
          style={{ margin: 0 }}
        >
          {status === "paid_off"
            ? "lunas"
            : status === "canceled"
              ? "batal"
              : status === "waiting_for_paid_off"
                ? "belum lunas"
                : "belum bayar"}
        </Tag>
      ) : (
        "-"
      ),
  },
  {
    width: 10,
    render: (item) => (
      <TrainingRegistrationsListActionsContainer trainingRegistration={item} />
    ),
  },
];
export default function TrainingRegistrationsList({
  trainingRegistrations = [],
  loading = false,
  totalTrainingRegistrations = 0,
  pageSize = 6,
  currentPage = 1,
  onChangeCurrentPage,
  onChangePageSize,
}) {
  return (
    <Table
      size="small"
      dataSource={trainingRegistrations.map((t) => ({ ...t, key: t.id }))}
      loading={loading}
      columns={getColumns({ trainingRegistrations })}
      pagination={{
        total: totalTrainingRegistrations,
        pageSize: pageSize,
        current: currentPage,
        onChange: (page, size) => {
          onChangeCurrentPage(page);
          onChangePageSize(size);
        },
        position: ["bottomCenter"],
      }}
    />
  );
}
