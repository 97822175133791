// Import package
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Modal, Radio, Space, Table, Tag } from "antd";
import {
  setErrorMessage,
  setLoadingMessage,
  setSuccessMessage,
} from "../../../../appRedux/actions/Common";
import { createTask } from "../../../../appRedux/actions/Task";

// Import file
import TrainingRegistrationsListActions from "../../../../components/adminRole/trainingRegistration/TrainingRegistrationsListActions";
import {
  deleteTrainingRegistration,
  getTrainingRegistrations,
  updateTrainingRegistration,
  sendDpPaymentInformtionEmailAndWa,
  sendPaidOffPaymentInformtionEmailAndWa,
  getTrainings,
  rescheduleTrainingRegistration
} from "../../../../appRedux/actions/TrainingRegistration";
import { toRupiah } from "../../../../libs";

export default function TrainingRegistrationsListActionsContainer({
  trainingRegistration,
}) {
  const messageKey = "TrainingRegistrationsListActionsContainer";
  const { id, status } = trainingRegistration;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let filter = queryString.parse(useLocation().search);
  const [modal, contextHolder] = Modal.useModal()
  let instance = null
  let newTrainingId = null

  const handleDelete = () => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin menghapus registrasi ini ?",
      onOk: () =>
        dispatch(deleteTrainingRegistration(id)).then(() =>
          dispatch(getTrainingRegistrations({ ...filter, current_page: 1 }))
        ),
    });
  };
  const handlePaidDownPayment = () => {
    dispatch(
      updateTrainingRegistration({
        ...trainingRegistration,
        already_paid: 500000,
        dp_deadline: null
      })
    ).then((data) => dispatch(sendDpPaymentInformtionEmailAndWa({ id: data.id })))
  };
  const handlePaidOff = () => {
    dispatch(
      updateTrainingRegistration({
        ...trainingRegistration,
        already_paid: trainingRegistration.total_price,
        dp_deadline: null
      })
    ).then((data) => dispatch(sendPaidOffPaymentInformtionEmailAndWa({ id: data.id })))
  };
  const handleCancel = () => {
    dispatch(
      updateTrainingRegistration({
        ...trainingRegistration,
        is_canceled: true,
      })
    );
  };
  const handleEdit = () => {
    history.push(
      "/training-registration/" + id + "/edit?back_link=" + location.pathname
    );
  };
  const handleInvoice = () => {
    dispatch(setLoadingMessage("Membuat invoice...", messageKey));
    dispatch(
      createTask({
        task: "generate_training_invoice",
        args: { training_registration_id: trainingRegistration.id },
      })
    )
      .then((result) => {
        dispatch(setSuccessMessage("Membuat invoice berhasil", messageKey));
        window.open(result.url, "_blank")
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  }
  const handleReceipt = () => {
    dispatch(setLoadingMessage("Membuat kuitansi...", messageKey));
    dispatch(
      createTask({
        task: "generate_training_receipt",
        args: { training_registration_id: trainingRegistration.id },
      })
    )
      .then((result) => {
        dispatch(setSuccessMessage("Membuat kuitansi berhasil", messageKey));
        window.open(result.url, "_blank")
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
      });
  }

  const generateModalContent = (data, newId = null) => {
    return <>
      <Table
        dataSource={data.map((t) => ({ ...t, key: t.id }))}
        pagination={false}
        loading={false}
        tableLayout="auto"
        columns={[
          {
            title: "#",
            dataIndex: "id",
            align: "center",
            width: 1,
          },
          {
            title: "Training",
            dataIndex: "training_option",
            render: (training_option) => <div>{training_option.name}</div>,
          },
          {
            title: "Lokasi",
            dataIndex: "is_online",
            key: "is_online",
            render: (isOnline, field) => {
              let backgroundColor = "#faf70d";
              return {
                props: {
                  style: { background: !field.is_running ? backgroundColor : "" }
                },
                children: (
                  <>
                    {isOnline &&
                      <Tag color="green" style={{ margin: 1 }}>ONLINE</Tag>
                    }
                    {((!isOnline && field.location) || field.is_offline) &&
                      <Tag color="blue" style={{ margin: 1 }}>{field.location}</Tag>
                    }
                  </>
                )
              }
            },
          },
          {
            title: "Jadwal",
            dataIndex: "dates_summary",
            render: (dates_summary) => <div>{dates_summary}</div>,
          },
          {
            title: "Harga",
            dataIndex: "price",
            render: (price) => <div>{toRupiah(price)}</div>,
          },
          {
            dataIndex: "id",
            render: (id) => <>
              <Button
                color="danger"
                type={newTrainingId == id ? "primary" : ""}
                onClick={() => {
                  newTrainingId = id
                  instance.update({
                    content: generateModalContent(data, id),
                  })
                }}>Pilih</Button>
            </>,
          },
        ]}
      />
    </>
  }

  const handleReschedule = async () => {
    const training_option_id = trainingRegistration.training.training_option_id
    const { data, meta } = await getTrainings({
      training_option_id: training_option_id,
      status: "available",
    })
    newTrainingId = null
    instance = modal.confirm({
      title: "Pilih Jadwal Training Baru dengan Harga Sekarang " + toRupiah(trainingRegistration.total_price),
      maskClosable: true,
      closable: true,
      width: {
        xs: '90%',
        sm: '80%',
        md: '70%',
        lg: '60%',
        xl: '50%',
        xxl: '40%',
      },
      content: generateModalContent(data),
      okText: "Jadwalkan Ulang",
      onOk: () => {
        if (!newTrainingId)
          return false
        dispatch(
          rescheduleTrainingRegistration({
            id: trainingRegistration.id,
            training_id: newTrainingId,
          })
        ).then(() =>
          dispatch(getTrainingRegistrations({ ...filter, current_page: 1 }))
        )
      }
    });
    return
  }

  return <>
    {contextHolder}
    <TrainingRegistrationsListActions
      onDelete={handleDelete}
      showSetPaidDownPayment={status === "waiting_for_down_payment"}
      showSetPaidOff={
        status === "waiting_for_paid_off" ||
        status === "waiting_for_down_payment"
      }
      showCancel={status !== "canceled"}
      // showInvoice={status == "paid_off"}
      showInvoice={true}
      showReceipt={status == "paid_off"}
      onSetPaidDownpayment={handlePaidDownPayment}
      onSetPaidOff={handlePaidOff}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onInvoice={handleInvoice}
      onReceipt={handleReceipt}
      onReschedule={handleReschedule}
    />
  </>
}
