import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import CertVerificationPage from "../CertVerificationPage";
import { setInitUrl } from "../../appRedux/actions";
import idLocale from "antd/lib/locale/id_ID";
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";
import CircularProgress from "../../components/CircularProgress";
import { getUser } from "../../appRedux/actions";
import InfoView from "../../components/InfoView";
import ResetPassword from "../ResetPassword";
import ResetPasswordConfirm from "../ResetPasswordConfirm";
import VerifyRegistration from "../VerifyRegistration";
import ResendVerification from "../ResendVerification";
import { CounterPage } from "../../routes/CounterPage";
import RegisterPage from "../../routes/RegisterPage";

const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  loadingAuthUser,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("full-layout");
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove("full-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("boxed-layout");
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("full-layout");
    document.body.classList.add("framed-layout");
  }
};

const setNavStyle = (navStyle) => {
  if (
    navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER
  ) {
    document.body.classList.add("full-scroll");
    document.body.classList.add("horizontal-layout");
  } else {
    document.body.classList.remove("full-scroll");
    document.body.classList.remove("horizontal-layout");
  }
};

const App = () => {
  const { locale, navStyle, layoutType, themeType, isDirectionRTL } =
    useSelector(({ settings }) => settings);
  const { token, initURL, loadingAuthUser, authUser } = useSelector(
    ({ auth }) => auth
  );
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add("rtl");
      document.documentElement.setAttribute("data-direction", "rtl");
    } else {
      document.documentElement.classList.remove("rtl");
      document.documentElement.setAttribute("data-direction", "ltr");
    }
  }, [isDirectionRTL]);

  useEffect(() => {
    if (locale) document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
    } else if (document.body.classList.contains("dark-theme")) {
      document.body.classList.remove("dark-theme");
    }
  }, [themeType]);

  useEffect(() => {
    if (
      location.pathname.split("/")[1] !== "verify-registration" &&
      location.pathname.split("/")[1] !== "password-reset" &&
      location.pathname !== "/signin" &&
      location.pathname !== "/signup"
    ) {
      dispatch(getUser(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname + location.search));
    }
  });

  useEffect(() => {
    if (location.pathname === "/") {
      if (authUser === null) {
        history.push("/signin");
      } else if (
        initURL === "" ||
        initURL === "/" ||
        initURL === "/signin" ||
        initURL === "/signup" ||
        initURL === "/password-reset" ||
        initURL.split("/")[1] === "verify-registration" ||
        initURL.split("/")[1] === "password-reset"
      ) {
        history.push("/dashboard");
      } else {
        history.push(initURL);
      }
    }
  }, [authUser, initURL, location, history]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];

  return loadingAuthUser && authUser === null ? (
    <CircularProgress />
  ) : (
    <ConfigProvider
      // locale={currentAppLocale.antd}
      locale={idLocale}
      direction={isDirectionRTL ? "rtl" : "ltr"}
    >
      <IntlProvider
        // locale={currentAppLocale.locale}
        locale="id"
        messages={currentAppLocale.messages}
      >
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/daftar" component={RegisterPage} />
          <Route exact path="/password-reset" component={ResetPassword} />
          <Route
            exact
            path="/password-reset/:token"
            component={ResetPasswordConfirm}
          />
          <Route
            exact
            path="/verify-registration/:token"
            component={VerifyRegistration}
          />
          <Route
            path="/cert-verification"
            exact
            component={CertVerificationPage}
          />
          <Route
            path="/resend-verification"
            exact
            component={ResendVerification}
          />
          <Route
            path="/counter"
            exact
            roles={["admin"]}
            component={CounterPage}
          />
          <RestrictedRoute
            path={`${match.url}`}
            authUser={authUser}
            loadingAuthUser={loadingAuthUser}
            location={location}
            component={MainApp}
          />
        </Switch>
        <InfoView />
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
