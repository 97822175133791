import { useParams } from "react-router-dom";
import PageContentWrapper from "../../components/PageContentWrapper";
import { useEffect, useState } from "react";
import CircularProgress from "../../components/CircularProgress";
import { getTrainingMaterialPdf } from "../../appRedux/actions/TrainingMaterial";
import { Document, Page, pdfjs } from 'react-pdf'
import "../../assets/react-pdf-custom.css";
import { Layout, Space } from 'antd';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { contentStyle, footerStyle, headerStyle, layoutStyle } from "../../util/LayoutStyles";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const { Header, Footer, Sider, Content } = Layout;

export default function TrainingMaterialPdf() {
  const { trainingMaterialId, trainingRegistrationId } = useParams()
  const [pdf, setPdf] = useState(null)
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData() {
    getTrainingMaterialPdf(trainingMaterialId, trainingRegistrationId).then(data => {
      setPdf(data)
    })
  }

  function nextPage() {
    if (pageNumber < numPages)
      setPageNumber(pageNumber + 1)
  }

  function previousPage() {
    if (pageNumber > 1)
      setPageNumber(pageNumber - 1)
  }

  return (
    <PageContentWrapper
      backLink={"/training-registration/" + trainingRegistrationId}
      title={"Materi Training"}>
      {pdf ? (
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div style={{ cursor: 'pointer' }} onClick={previousPage}><LeftCircleOutlined /></div>
            <div style={{ width: '50%' }}>{pdf.name}</div>
            <div style={{ cursor: 'pointer' }} onClick={nextPage}><RightCircleOutlined /></div>
          </Header>
          <Content style={contentStyle}>
            <Document
              file={pdf.url}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<CircularProgress />}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          </Content>
          <Footer style={footerStyle}>
            <div style={{ cursor: 'pointer' }} onClick={previousPage}><LeftCircleOutlined /></div>
            <div style={{ width: '50%' }}>
              Halaman {pageNumber} dari {numPages}
            </div>
            <div style={{ cursor: 'pointer' }} onClick={nextPage}><RightCircleOutlined /></div>
          </Footer>
        </Layout>
      ) : (
        <CircularProgress />
      )}
    </PageContentWrapper>
  )
}