// Import package
import React, { useEffect, useState, useCallback } from "react";
import { Button, Card, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
  getAffiliatePointsHistory,
  getParticipant,
  getTrainings,
  setTrainingsLocation,
  setTrainingsPagination,
} from "../../appRedux/actions";

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import AffiliateTabs from "../../components/participantRole/affiliate/AffiliateTabs";
import AffiliatePointHistoryList from "../../components/participantRole/affiliate/AffiliatePointHistoryList";
import AffiliateParticipantHistoryList from "../../components/participantRole/affiliate/AffiliateParticipantHistoryList";
import { getAffiliateParticipantsHistory } from "../../appRedux/actions/AffiliateParticipantHistory";
import CircularProgress from "../../components/CircularProgress";
import { getConfiguration } from '../../appRedux/actions/Configuration'

// Component
export default function AffiliatePage() {
  const { authUser } = useSelector(({ auth }) => auth);
  const [dataCode, setDataCode] = useState("");
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const activeTab = query.get("tab") || "history";
  const { affiliateParticipantsHistory, affiliateParticipantsHistoryLoading } =
    useSelector(
      ({ affiliateParticipantsHistory }) => affiliateParticipantsHistory
    );
  const { affiliatePointsHistory, affiliatePointsHistoryLoading } =
    useSelector(
      ({ affiliatePointsHistory }) => affiliatePointsHistory
    );
  const { participant, participantLoading } = useSelector(
    ({ participant }) => participant
  );
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null)

  const fetchMyAPI = useCallback(async () => {
    let result = await dispatch(getParticipant(authUser.participant.id))
    dispatch(
      getAffiliateParticipantsHistory({
        affiliate_code: result.affiliate_code,
      })
    );
    dispatch(
      getAffiliatePointsHistory()
    )
  }, [dispatch])

  useEffect(() => {
    let configurationName = "deactivated_affiliate_message"
    if (participant.is_affiliate) {
      fetchMyAPI()
      configurationName = "activated_affiliate_message"
    }
    getConfiguration(configurationName)
      .then(data => {
        setMessage(data.value.replace("{affiliate_code}", participant.affiliate_code).replace("{affiliate_point}", participant.affiliate_point))
      })
  }, [fetchMyAPI]);

  const handleTabsChange = (key) => {
    dispatch(
      getAffiliateParticipantsHistory({
        affiliate_code: participant.affiliate_code,
      })
    );
    dispatch(
      getAffiliatePointsHistory()
    )
    history.push("/affiliate?tab=" + key);
  };

  return participantLoading && affiliateParticipantsHistoryLoading && affiliatePointsHistoryLoading ? (
    <CircularProgress />
  ) : participant && affiliateParticipantsHistory && affiliatePointsHistory ? (
    <PageContentWrapper title="Affiliate">
      <div dangerouslySetInnerHTML={{ __html: message }}></div>
      {participant.is_affiliate && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <AffiliateTabs
            activeKey={activeTab}
            onChange={handleTabsChange}
          />
          {activeTab === "history" && (
            <AffiliatePointHistoryList
              affiliatePointsHistory={affiliatePointsHistory}
              loading={affiliatePointsHistoryLoading}
            //   trainings={trainings}
            //   loading={getTrainingsLoading}
            //   totalTrainings={totalTrainings}
            //   onChangePagination={handleChangeTrainingsPagination}
            />
          )}
          {activeTab === "participant" && (
            <AffiliateParticipantHistoryList
              affiliateParticipantsHistory={affiliateParticipantsHistory}
              loading={affiliateParticipantsHistoryLoading}
            //   totalTrainings={totalTrainings}
            //   onChangePagination={handleChangeTrainingsPagination}
            //   pageSize={getTrainingsFilter.page_size}
            // currentPage={getTrainingsFilter.current_page}
            />
          )}
        </Space>
      )}
    </PageContentWrapper>
  ) : (
    <CircularProgress />
  );
}
