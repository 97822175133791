import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrainingOption,
  generateDummyCertificateTemplate,
} from "../../appRedux/actions";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import CircularProgress from "../../components/CircularProgress";
import PageContentWrapper from "../../components/PageContentWrapper";
import { Button, Tabs, Space } from "antd";
import TrainingOptionDescription from "../../components/adminRole/trainingOption/TrainingOptionDescription";
import TrainingOptionMaterial from "../../components/adminRole/trainingOption/TrainingOptionMaterial";
// import TrainingOptionActions from "../../components/trainingOption/TrainingOptionActions";
import TrainingOptionActionsContainer from "../../containers/adminRole/trainingOption/TrainingOptionActionsContainer";
import { generateDownloadUrl } from "../../appRedux/actions/Blob";
import slugify from "slugify";

export default function DetailTrainingOptionPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { trainingOption, trainingOptionLoading } = useSelector(
    ({ trainingOption }) => trainingOption
  );
  const { trainingOptionId } = useParams();
  useEffect(() => {
    dispatch(getTrainingOption(trainingOptionId));
  }, [dispatch]);
  const handleTabChange = (v) =>
    history.push("/training-option/" + trainingOption.id + "/" + v);
  const subpath = location.pathname.split("/")[3] || "description";
  const handleDownloadCertificateTemplate = () => {
    dispatch(
      generateDownloadUrl({
        path: trainingOption.certificate_template_blob_path,
        // name: slugify("template-sertifikat-" + trainingOption.name + ".png", {
        //   lower: true,
        // }),
      })
    ).then((res) => window.open(res.download_url, "_blank"));
  };
  const handleDownloadDummyCertificate = () => {
    dispatch(generateDummyCertificateTemplate(trainingOption.id)).then((url) =>
      window.open(url, "_blank")
    );
  };
  return trainingOption && !trainingOptionLoading ? (
    <PageContentWrapper
      title={trainingOption.name}
      backLink="/training-option/"
      extra={
        <TrainingOptionActionsContainer trainingOptionId={trainingOption.id} subpath={subpath} />
      }
      footer={
        <Tabs activeKey={subpath} onChange={handleTabChange}>
          <Tabs.TabPane key="description" tab="Deskripsi" />
          <Tabs.TabPane key="material" tab="Materi" />
        </Tabs>
      }
    >
      {subpath === "description" && (
        <TrainingOptionDescription
          trainingOption={trainingOption}
          onDownloadDummyCertificate={handleDownloadDummyCertificate}
        />
      )}
      {subpath === "material" && <TrainingOptionMaterial trainingOptionId={trainingOption.id} />}
    </PageContentWrapper>
  ) : trainingOptionLoading ? (
    <CircularProgress />
  ) : (
    "pilihan training tidak ditemukan"
  );
}
