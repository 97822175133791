import { Alert, Button, Card, Checkbox, DatePicker, Form, Input, Modal, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import InfoView from '../../components/InfoView'
import { FETCH_ERROR } from '../../constants/ActionTypes'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-number-input'
import { baseRequest } from '../../util/Api'
import { CheckCircleOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { toRupiah } from '../../libs'

const RegisterPage = (props) => {
  const [showAlert, setShowAlert] = useState(false)
  const [knowFrom, setKnowFrom] = useState([])
  const [rules, setRules] = useState("")
  const [locations, setLocations] = useState([])
  const [trainingOptions, setTrainingOptions] = useState([])
  const [trainings, setTrainings] = useState([])
  const [provinces, setProvinces] = useState([])
  const [shirtSizes, setShirtSizes] = useState([])
  const [componentDisabled, setComponentDisabled] = useState(false)
  const recaptchaRef = React.createRef()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  useEffect(() => {
    baseRequest("get", "/public/daftar", null).then(data => {
      setKnowFrom(data["know_from_where"])
      setRules(data["training_rules"])
      let options = [];
      data["locations"].map((value) => {
        options.push({
          value: value,
          label: value,
        });
      });
      setLocations(options)
      setTrainingOptions(data["training_options"])
      setProvinces(data["provinces"])
      setShirtSizes(data["shirt_sizes"])
    })
  }, [])

  const onFinishFailed = (errorInfo) => { };

  const onFinish = (values) => {
    values.recaptcha = recaptchaRef.current.getValue();
    if (!values.recaptcha) {
      dispatch({ type: FETCH_ERROR, payload: "Harap masukkan Captcha!" })
      return false;
    }
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah sudah yakin dengan data yang dimasukkan?",
      onOk: () => {
        baseRequest("post", "/public/daftar", null, values).then(data => {
          if (data) {
            setShowAlert(true)
            setComponentDisabled(true)
            const elmnt = document.getElementById("alert-message")
            elmnt.scrollIntoView({ behavior: 'smooth' })
          }
        }).catch(e => {
          dispatch({ type: FETCH_ERROR, payload: e })
        })
      }
    })
  }

  const onChangeTrainingsFilter = async (filter) => {
    if (!filter.start_date || !filter.end_date || !filter.training_option_id)
      return
    filter = {
      ...filter,
      page_size: 10000,
    }
    const { data, meta } = await baseRequest("get", "/public/trainings/", { ...filter, page_size: 10000 })
    setTrainings(data)
  }

  return (
    <section className="ant-layout ant-layout-has-sider gx-app-layout">
      <main className="ant-layout-content gx-layout-content">
        <div className='gx-main-content-wrapper' style={{ "padding": "1em" }} id="alert-message">
          <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
              {showAlert && (
                <Alert
                  message={"Registrasi Berhasil"}
                  description={"Anda berhasil terdaftar dalam training, silahkan check email untuk melihat data akun dan jadwal training (check folder spam, promotions, dll)"}
                  type="success"
                  showIcon
                  closable={false}
                  action={
                    <Link to="/dashboard">
                      <Button type='primary'>Login</Button>
                    </Link>
                  }
                />
              )}
              <div className="gx-app-login-main-content">
                <div className="gx-app-logo-content" style={{ "backgroundColor": "#E13849" }}>
                  <div className="gx-app-logo-content-bg">
                    <img alt="example" src="bg-rg.jpg" style={{
                      "height": "auto",
                      "position": "absolute",
                      "left": 0,
                      "bottom": 0,
                    }} />
                  </div>
                  <div className="gx-app-logo-wid">
                    <h1>
                      Daftar
                    </h1>
                    <p>
                      Daftarkan diri kamu pada training terbaik dari kami
                    </p>
                  </div>
                  <div className="gx-app-logo">
                    <img
                      alt="example"
                      src="/assets/images/logo-idn.png"
                      width="150"
                    />
                  </div>
                </div>

                <div className="gx-app-login-content">
                  <Form
                    disabled={componentDisabled}
                    initialValues={{ remember: true }}
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="gx-signin-form gx-form-row0"
                    scrollToFirstError={true}
                    form={form}
                    onValuesChange={(changedValues, allValues) => {
                      if (
                        "training_option_id" in changedValues ||
                        "training_type" in changedValues ||
                        "training_location" in changedValues ||
                        "training_dates" in changedValues
                      ) {
                        onChangeTrainingsFilter({
                          training_option_id: allValues.training_option_id,
                          is_online: allValues.training_type === "online",
                          location:
                            allValues.training_type === "offline"
                              ? (allValues.training_location ? allValues.training_location : "XXXXXXXXXX")
                              : null,
                          start_date: allValues.training_dates ? allValues.training_dates.startOf("month").toISOString() : null,
                          end_date: allValues.training_dates ? allValues.training_dates.endOf("month").toISOString() : null,
                          status: "available",
                        })
                      }
                    }}
                  >
                    <p>
                      Form ini khusus bagi yg belum punya akun my.idn.id.
                      Jika kamu sudah punya silahkan <Link to="/sigin">login di sini</Link>.
                    </p>
                    <p>
                      Jika ada kendala pendaftaran, silahkan hubungi admin melalui <a href="https://wa.me/6281908191001?text=Saya%20ada%20kendala%20pendaftaran" target="_blank"
                        className="ant-btn ant-btn-sm ant-btn-info"
                        style={{ marginTop: "auto", marginBottom: "auto" }}>
                        <WhatsAppOutlined /> Whatsapp 081908191001
                      </a>
                    </p>
                    <Form.Item
                      label="Nama Lengkap"
                      rules={[{ required: true, message: "Harap masukan nama lengkap!" }]}
                      name="name"
                    >
                      <Input disabled={componentDisabled} />
                    </Form.Item>
                    <Form.Item
                      label="Nama Panggilan"
                      rules={[{ required: true, message: "Harap masukan nama panggilan!" }]}
                      name="nickname"
                    >
                      <Input disabled={componentDisabled} />
                    </Form.Item>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Harap masukkan E-mail!",
                        },
                      ]}
                    >
                      <Input type='email' disabled={componentDisabled} />
                    </Form.Item>
                    <Form.Item
                      name="whatsapp_number"
                      label="Nomor whatsapp"
                      rules={[
                        {
                          required: true,
                          message: "Mohon masukkan nomor whatsapp!",
                        },
                      ]}
                    >
                      <PhoneInput
                        defaultCountry="ID"
                        style={{ width: "100%" }}
                        placeholder="081362778829"
                        disabled={componentDisabled}
                      />
                    </Form.Item>
                    <Form.Item
                      name="company"
                      label="Perusahaan"
                      rules={[
                        {
                          required: true,
                          message: "Mohon masukkan nama perusahaan!",
                        },
                      ]}
                    >
                      <Input disabled={componentDisabled} />
                    </Form.Item>
                    <Form.Item
                      name="job_position"
                      label="Jabatan / posisi di perusahaan"
                      rules={[
                        {
                          required: true,
                          message: "Mohon masukkan jabatan / posisi di perusahaan!",
                        },
                      ]}
                    >
                      <Input disabled={componentDisabled} />
                    </Form.Item>
                    <Form.Item
                      name="address"
                      label="Alamat tempat tinggal"
                      rules={[
                        {
                          required: true,
                          message: "Mohon masukkan alamat tempat tinggal!",
                        },
                      ]}
                    >
                      <Input.TextArea disabled={componentDisabled} />
                    </Form.Item>
                    <Form.Item
                      name="province"
                      label="Provinsi"
                      rules={[
                        {
                          required: true,
                          message: "Mohon masukkan provinsi tempat tinggal!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Pilih provinsi tempat tinggal"
                        disabled={componentDisabled}
                        showSearch
                        optionFilterProp="label"
                        options={provinces.map(province => {
                          return {
                            value: province,
                            label: province,
                          }
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      name="shirt_size"
                      label="Ukuran kaos"
                      rules={[
                        {
                          required: true,
                          message: "Mohon masukkan ukuran kaos!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Pilih ukuran kaos"
                        options={shirtSizes}
                        disabled={componentDisabled} />
                    </Form.Item>
                    <Form.Item
                      name="know_idn_from_where"
                      label="Tahu ID-Networkers darimana?"
                      rules={[
                        {
                          required: true,
                          message: "Mohon masukkan tahu dari mana!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Pilih"
                        disabled={componentDisabled}
                        optionFilterProp="label"
                        options={knowFrom.map(item => {
                          return {
                            value: item,
                            label: item,
                          }
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Pilihan training"
                      name="training_option_id"
                      rules={[
                        { required: true, message: "Mohon pilih pilihan training" },
                      ]}
                    >
                      <Select
                        disabled={componentDisabled}
                        showSearch
                        placeholder="pilih pilihan training"
                        optionFilterProp="label"
                        options={trainingOptions.map(option => {
                          return {
                            value: option.id,
                            label: option.name,
                          }
                        })}
                      />
                    </Form.Item>
                    <p>
                      Info lengkap training silahkan cek di: <a target='_blank' href='https://www.idn.id/training'>www.idn.id/training</a>
                    </p>
                    <Form.Item label="Tipe training" name="training_type" required>
                      <Select disabled={componentDisabled} defaultValue={"online"}>
                        <Select.Option value="offline">Offline</Select.Option>
                        <Select.Option value="online">Online</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                      {
                        () => (
                          <Form.Item
                            label="Lokasi training"
                            name="training_location"
                            required
                            style={{
                              display:
                                form.getFieldValue("training_type") === "offline"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <Select
                              options={locations}
                              disabled={componentDisabled}
                              showSearch
                              optionFilterProp="label"
                            >
                            </Select>
                          </Form.Item>
                        )
                      }
                    </Form.Item>
                    <Form.Item label="Bulan training" name="training_dates" required>
                      <DatePicker picker="month" format="MMMM YYYY" allowClear={false} disabled={componentDisabled} />
                    </Form.Item>
                    <Form.Item
                      label="Jadwal training"
                      rules={[{ required: true, message: "mohon pilih jadwal training" }]}
                      name="training_id"
                    >
                      {trainings.length > 0 ? (
                        <Select
                          disabled={componentDisabled}
                          placeholder="pilih jadwal training"
                          showSearch
                          optionFilterProp="label"
                          options={trainings.map(option => {
                            return {
                              value: option.id,
                              label: option.dates_summary + " - " + toRupiah(option.price),
                            }
                          })}
                        />
                      ) : (
                        <>
                          {(form.getFieldValue("training_option_id") && ((form.getFieldValue("training_type") == "offline" && form.getFieldValue("training_location")) || form.getFieldValue("training_type") == "online") && form.getFieldValue("training_dates")) ? (
                            <Alert message={<span>Kuota sudah penuh atau jadwal belum tersedia di bulan yg anda pilih.<br />Silahkan pilih jadwal bulan berikutnya atau hubungi admin ID-Networkers via whatsapp 0819-0819-1001</span>}
                              type="info"
                              showIcon
                              action={
                                <a href="https://wa.me/6281908191001?text=Saya%20ada%20kendala%20pendaftaran" target="_blank"
                                  className="ant-btn ant-btn-sm ant-btn-info"
                                  style={{ marginTop: "auto", marginBottom: "auto" }}>
                                  <WhatsAppOutlined /> Whatsapp
                                </a>
                              } />
                          ) : (
                            <Alert message="Silahkan pilih Pilihan training, Tipe training, Lokasi training (jika offline), dan Bulan training terlebih dahulu."
                              type="info" />
                          )}
                        </>
                      )}
                    </Form.Item>
                    <Form.Item
                      rules={[{ required: true, message: "Mohon untuk menyetujui peraturan training" }]}
                      name="rules_checked" valuePropName="checked" label={null}
                      onChange={(e) => {
                        if (e.target.checked)
                          form.setFieldsValue({ rules_checked: "checked" })
                        else
                          form.setFieldsValue({ rules_checked: null })
                      }}
                    >
                      <Checkbox disabled={componentDisabled}>Saya telah menyetujui peraturan training <a href="#" onClick={() => {
                        Modal.info({
                          title: "Peraturan Training",
                          centered: true,
                          width: 600,
                          closable: true,
                          icon: <CheckCircleOutlined />,
                          content: <div dangerouslySetInnerHTML={{ __html: rules }}></div>,
                          onOk: () => { },
                        })
                      }}>berikut ini</a></Checkbox>
                    </Form.Item>


                    <Form.Item>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={() => { }}
                        disabled={componentDisabled}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" className="gx-mb-0 ant-btn-block" htmlType="submit" disabled={componentDisabled}>
                        Klik Untuk Daftar
                      </Button>
                      {/* <span>
                        atau
                      </span>{" "}
                      <Link to="/signin">
                        Login
                      </Link> */}
                    </Form.Item>
                  </Form>
                </div>
                <InfoView />
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  )
}

export default RegisterPage