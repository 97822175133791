import { Button, Card, Dropdown, List, Menu, PageHeader, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { toRupiah } from "../../libs";
import { getAccounts, setActive, setDefault } from "../../appRedux/actions/Accounts";
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function AccountsPage() {
  const getColumns = () => [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 10,
      responsive: ["sm"],
    },
    {
      title: "Nama & Nomor Rekening",
      dataIndex: "name",
      key: "name",
      // responsive: ["sm"],
      render: (name, field) => <>
        <div style={{ width: "100%" }}>{name}</div>
        <div style={{ width: "100%" }}>{field.number}</div>
      </>
    },
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
      responsive: ["sm"],
    },
    // {
    //   title: "Nomor Rekening",
    //   dataIndex: "number",
    //   key: "number",
    //   responsive: ["sm"],
    // },
    {
      title: "Nama Pemilik",
      dataIndex: "owner_name",
      key: "owner_name",
      responsive: ["sm"],
    },
    {
      title: "Saldo",
      dataIndex: "balance",
      key: "balance",
      responsive: ["sm"],
      render: (balance) => <div style={{ width: "100%", textAlign: "right" }}>{toRupiah(balance)}</div>
    },
    {
      title: "Kategori Training",
      dataIndex: "training_categories",
      key: "training_categories",
      // responsive: ["sm"],
      render: (training_categories) => {
        return <>{(training_categories.length > 0) && training_categories.map(training_category => {
          return <Tag>{training_category.name}</Tag>
        })
        }
        </>
      }
    },
    {
      title: "Lokasi",
      dataIndex: "locations",
      key: "locations",
      // responsive: ["sm"],
      render: (locations) => {
        return <>{(locations.length > 0) && locations.map(location => {
          return <Tag>{location.location}</Tag>
        })
        }
        </>
      }
    },
    {
      title: "Keterangan",
      dataIndex: "balance",
      key: "balance",
      // responsive: ["sm"],
      render: (balance, field) => {
        return <>
          <Tag icon={field.active ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
            color={field.active ? "green" : "red"}
            style={{ margin: 1 }}
          >
            {field.active ? "Aktif" : "Tidak Aktif"}
          </Tag>
          {field.default &&
            <Tag icon={<CheckCircleOutlined />}
              color="geekblue"
              style={{ margin: 1 }}
            >Default</Tag>
          }
        </>
      }
    },
    {
      dataIndex: "id",
      key: "id",
      responsive: ["sm"],
      align: "right",
      render: (id, field) => {
        return <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => { onCategoryEdit(id) }}>
                Edit
              </Menu.Item>
              {(field.active && !field.default) &&
                <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => { onSetActive(id, false) }}>
                  Non Aktifkan
                </Menu.Item>
              }
              {!field.active &&
                <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => { onSetActive(id, true) }}>
                  Aktifkan
                </Menu.Item>
              }
              {(field.active && !field.default) &&
                <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => { onSetDefault(id, true) }}>
                  Sebagai Default
                </Menu.Item>
              }
            </Menu>
          }
          placement="bottomRight"
          trigger={["click"]}
        >
          <i className="gx-icon-btn icon icon-ellipse-v" />
        </Dropdown>
      }
    },
  ];

  const [accounts, setAccounts] = useState([])
  const [loading, setLoading] = useState(true)
  const history = useHistory();

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData() {
    getAccounts().then(data => {
      setAccounts(data)
      setLoading(false)
    })
  }

  function onSetActive(id, enabled) {
    setLoading(true)
    setActive(id, enabled).then(() => {
      fetchData()
    })
  }

  function onSetDefault(id, defa) {
    setLoading(true)
    setDefault(id, defa).then(() => {
      fetchData()
    })
  }

  function onCategoryEdit(id) {
    history.push("/accounts/" + id + "/edit")
  }

  return <>
    <PageHeader
      ghost={false}
      title="Multi Rekening"
    // extra={
    //   <>
    //     <Link to="/accounts/add">
    //       <Button
    //         style={{ margin: 0 }}
    //         type="primary"
    //         icon={<PlusOutlined />}
    //       >
    //         Tambah
    //       </Button>
    //     </Link>
    //   </>
    // }
    />

    <Card bordered={false} style={{ borderRadius: 0 }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Table
          dataSource={accounts.map((m) => ({ ...m, key: m.id }))}
          loading={loading}
          columns={getColumns()}
          pagination={false}
        />
      </Space>
    </Card>
  </>
}
