import { message } from "antd";
import { baseRequest } from "../../util/Api";
import { v4 } from "uuid";

export const getTrainingMaterials = (filter = null) =>
  new Promise((resolve) => {
    baseRequest("get", "/training-material", filter)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        message.error(e)
      });
  });

export const getTrainingMaterialPdf = (trainingMaterialId, trainingRegistrationId) =>
  new Promise((resolve) => {
    baseRequest("get", "/training-material/pdf/" + trainingMaterialId + "/" + trainingRegistrationId)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        message.error(e)
      });
  });

export const getTrainingMaterialUploadUrl = (trainingOptionId) =>
  new Promise((resolve) => {
    baseRequest("get", "/training-material/upload-url/" + trainingOptionId)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        message.error(e)
      });
  });

export const createTrainingMaterial = (data) =>
  new Promise((resolve) => {
    const key = v4()
    message.open({ key, type: 'loading', content: "Menambah materi training ..." })
    baseRequest("post", "/training-material", null, data)
      .then((newData) => {
        message.success({ key, type: 'success', content: "Menambah materi training berhasil" })
        resolve(newData);
      })
      .catch((e) => {
        message.error({ key, type: 'error', content: e })
      });
  });

export const deleteTrainingMaterial = (materialId) =>
  new Promise((resolve) => {
    baseRequest("delete", "/training-material/" + materialId)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        message.error(e)
      });
  });


export const deleteTrainingMaterialPdf = (params) =>
  new Promise((resolve) => {
    baseRequest("delete", "/training-material/pdf", params)
      .then(() => {
        resolve(true);
      })
      .catch((e) => {
        message.error(e)
        resolve(false)
      });
  });