// Import package
import React from "react";
import { Descriptions } from "antd";

// Import file
import "react-phone-number-input/style.css";

// Component
export default function ParticipantDetail({ participant }) {
  const {
    name,
    email,
    whatsapp_number,
    company,
    job_position,
    address,
    affiliate_code,
    affiliate_point,
    shirt_size,
    know_idn_from_where,
    nickname,
    province,
    is_affiliate,
  } = participant;
  return (
    <Descriptions bordered size="middle" column={{ xs: 1, sm: 1 }}>
      <Descriptions.Item label="Nama">{name}</Descriptions.Item>
      <Descriptions.Item label="Nama Panggilan">{nickname}</Descriptions.Item>
      <Descriptions.Item label="Alamat Email">{email}</Descriptions.Item>
      <Descriptions.Item label="Nomor Whatsapp">
        <a href={"https://wa.me/" + whatsapp_number} target= "_blank" rel="noreferrer">
          {whatsapp_number}
        </a>
      </Descriptions.Item>
      <Descriptions.Item label="Perusahaan">{company}</Descriptions.Item>
      <Descriptions.Item label="Jabatan / Posisi di perusahaan">
        {job_position}
      </Descriptions.Item>
      <Descriptions.Item label="Alamat Tempat Tinggal">
        {address}
      </Descriptions.Item>
      <Descriptions.Item label="Provinsi">
        {province}
      </Descriptions.Item>
      <Descriptions.Item label="Terdaftar Affiliasi">
        {is_affiliate ? "Ya" : "Tidak"}
      </Descriptions.Item>
      <Descriptions.Item label="Kode Affiliasi">
        {affiliate_code}
      </Descriptions.Item>
      <Descriptions.Item label="Point Affiliasi">
        {affiliate_point}
      </Descriptions.Item>
      <Descriptions.Item label="Ukuran Kaos">{shirt_size}</Descriptions.Item>
      <Descriptions.Item label="Tahu ID-Networkers darimana">{know_idn_from_where === null ? "-" : know_idn_from_where}</Descriptions.Item>
    </Descriptions>
  );
}
