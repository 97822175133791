// Import package
import React, { Fragment } from "react";
import { Descriptions, Tag, Badge, List } from "antd";
import moment from "moment";
import PhoneNumber from "awesome-phonenumber";

// Component
export default function TrainingDetail({ training }) {
  return (
    <Fragment>
      <Descriptions
        bordered
        size="small"
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label="Tipe">
          {training.is_online &&
            <Tag color="green" style={{ margin: 0,marginRight: "2px" }}>ONLINE</Tag>
          }
          {((!training.is_online && training.location) || training.is_offline) &&
            <Tag color="blue" style={{ margin: 0 }}>OFFLINE</Tag>
          }
          {/* <Tag
            style={{ margin: 0 }}
            color={training.is_online ? "green" : "blue"}
          >
            {training.is_online ? "ONLINE" : "OFFLINE"}
          </Tag> */}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Badge
            style={{ margin: 0 }}
            status={training.is_running ? "success" : "error"}
            text={training.is_running ? "Running" : "Batal"}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Tanggal">
          <List size="small">
            {training.dates.map((date) => (
              <List.Item key={date.id}>
                {moment(date.date, "YYYY-MM-DDT").format("dddd, D MMMM YYYY")}
              </List.Item>
            ))}
          </List>
        </Descriptions.Item>
        <Descriptions.Item label="Waktu">
          {training.time_start} - {training.time_end}
        </Descriptions.Item>
        <Descriptions.Item label="Lokasi">
          {training.location ? training.location : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Ruang">
          {training.room || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Peserta" span={2}>
          <List size="small">
            {training.participants.length > 0
              ? training.participants.map((participant) => (
                <List.Item key={participant.id}>
                  {participant.participant.name + " "}
                  {participant.participant.company &&
                    "- " + participant.participant.company + " "}
                  {participant.participant.whatsapp_number && (
                    <a
                      href={
                        "https://wa.me/" +
                        participant.participant.whatsapp_number
                      }
                    >
                      (
                      {participant.participant.whatsapp_number &&
                        new PhoneNumber(
                          participant.participant.whatsapp_number
                        ).getNumber("national")}
                      )
                    </a>
                  )}
                  {participant.is_retraining ? " ( Mengulang )" : " ( Regular )"}
                </List.Item>
              ))
              : "Belum ada peserta terdaftar"}
          </List>
        </Descriptions.Item>
      </Descriptions>
    </Fragment>
  );
}
