import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteTrainingMaterial, getTrainingMaterials } from "../../../../appRedux/actions/TrainingMaterial";
import { Button, Card, Dropdown, List, Menu, Modal, PageHeader, Space, Table, Tag } from "antd";
import { DeleteOutlined, FilePdfOutlined, LinkOutlined } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";

export default function TrainingOptionMaterial({ trainingOptionId }) {
  const [accounts, setAccounts] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData() {
    getTrainingMaterials({ training_option_id: trainingOptionId }).then(data => {
      setAccounts(data)
      setLoading(false)
    })
  }

  function deleteMaterial(id) {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin menghapus materi ini?",
      onOk: () => {
        deleteTrainingMaterial(id).then(() => {
          setLoading(true)
          fetchData()
        })
      }
    });
  }

  const getColumns = () => [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 10,
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Materi",
      dataIndex: "path",
      key: "path",
      render: (path, field) => {
        return <>
          <Paragraph copyable={{ text: path, }}>
            <a href={path} target="_blank">
              {path.length > 32 ?
                path.substring(0, 32 - 3) + "..." :
                path}
            </a>
            {field.type == "pdf" && (
              <FilePdfOutlined />
            )}
            {field.type == "link" && (
              <LinkOutlined />
            )}
          </Paragraph>
        </>
      },
    },
    {
      dataIndex: "id",
      key: "id",
      responsive: ["sm"],
      align: "right",
      render: (id) => {
        return <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="edit"
                icon={<DeleteOutlined />}
                onClick={() => { deleteMaterial(id) }}>
                Hapus
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={["click"]}
        >
          <i className="gx-icon-btn icon icon-ellipse-v" />
        </Dropdown>
      }
    },
  ];

  return <Space direction="vertical" style={{ width: "100%" }}>
    <Table
      dataSource={accounts.map((m) => ({ ...m, key: m.id }))}
      loading={loading}
      columns={getColumns()}
      pagination={false}
    />
  </Space>
}
