import React, { useEffect, useState } from "react";
import { Descriptions, Tag, Typography, Badge, Table, Button } from "antd";
import { CalendarOutlined, FilePdfOutlined, LinkOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { toRupiah } from "../../../libs";
import moment from "moment";
import { getTrainingMaterials } from "../../../appRedux/actions/TrainingMaterial";
import { useHistory } from "react-router-dom";

export default function TrainingRegistrationDetail({ data }) {
  const {
    status,
    training,
    is_retraining,
    total_price,
    already_paid,
    transfer_code,
    dp_deadline,
    created_at,
  } = data;

  const trainingOptionId = data.training.training_option_id
  const history = useHistory()
  const [materials, setMaterials] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData() {
    getTrainingMaterials({ training_option_id: trainingOptionId }).then(data => {
      setMaterials(data)
      setLoading(false)
    })
  }

  const getColumns = () => [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Materi",
      dataIndex: "path",
      key: "path",
      render: (path, field) => {
        return <>
          {field.type == "pdf" && (
            <Button color="primary" variant="outlined" icon={<FilePdfOutlined />}
              onClick={() => { history.push("/training-material-pdf/" + field.id + "/" + data.id) }}>
              Buka
            </Button>
          )}
          {field.type == "link" && (
            <a href={path} target="_blank">
              <Button color="primary" variant="outlined" icon={<LinkOutlined />}>
                Buka
              </Button>
            </a>
          )}
        </>
      },
    },
  ];

  return (
    <>
      <Descriptions bordered size="small" column={{ xs: 1, md: 2 }}>
        <Descriptions.Item label="Kode" span={2}>
          #{data.id}
        </Descriptions.Item>
        <Descriptions.Item label="Sudah Dibayar">
          {toRupiah(already_paid)}
        </Descriptions.Item>
        <Descriptions.Item label="Total Harga">
          {training.price > total_price && (
            <Typography.Text delete>{toRupiah(training.price)}</Typography.Text>
          )}{" "}
          {toRupiah(total_price)}
        </Descriptions.Item>
        <Descriptions.Item label="Jenis">
          {data.is_retraining ? "Mengulang" : "Reguler"}
        </Descriptions.Item>
        {data.is_retraining &&
          <>
            <Descriptions.Item label="Makan">
              {data.retraining_with_meal ? "Dengan Makan" : "Tanpa Makan"}
            </Descriptions.Item>
            <Descriptions.Item label="Training Kit">
              {data.retraining_received_kit ? "Sudah Dapat" : "Belum Dapat"}
            </Descriptions.Item>
          </>
        }
        <Descriptions.Item label="Kode Transfer">
          {transfer_code ? transfer_code : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Batas waktu pelunasan uang muka">
          {dp_deadline ? moment(dp_deadline).format("LLL") : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {!is_retraining ? (
            <Badge
              style={{ margin: 0 }}
              status={
                status === "paid_off"
                  ? "success"
                  : status === "canceled"
                    ? "error"
                    : "processing"
              }
              text={
                status === "paid_off"
                  ? "Lunas"
                  : status === "canceled"
                    ? "Batal"
                    : status === "waiting_for_paid_off"
                      ? "Belum lunas"
                      : "Belum bayar"
              }
            />
          ) : (
            "-"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Waktu Pendaftaran">
          {moment(created_at).format("LLL")}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <h2 className="title">
        <CalendarOutlined /> Training
      </h2>
      <Descriptions bordered size="small" column={{ xs: 1, md: 2 }}>
        <Descriptions.Item label="Kode" span={2}>
          #{data.training_id}
        </Descriptions.Item>
        <Descriptions.Item label="Nama">
          {training.training_option.name}
        </Descriptions.Item>
        <Descriptions.Item label="Jadwal">
          {training.dates_summary}
        </Descriptions.Item>
        <Descriptions.Item label="Tipe">
          <Tag
            style={{ margin: 0 }}
            color={data.is_online ? "magenta" : "blue"}
          >
            {data.is_online ? "online" : "offline"}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Lokasi">
          {!data.is_online
            ? training.location
            : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Badge
            style={{ margin: 0 }}
            status={training.is_running ? "success" : "error"}
            text={training.is_running ? "Berjalan" : "Batal"}
          />
        </Descriptions.Item>
      </Descriptions>
      <br />
      <h2 className="title">
        <UnorderedListOutlined /> Materi
      </h2>
      <Table
        dataSource={materials.map((m) => ({ ...m, key: m.id }))}
        loading={loading}
        columns={getColumns()}
        pagination={false}
      />
    </>
  );
}
