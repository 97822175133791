// Import package
import React from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import { useDispatch } from "react-redux";

// Import file
import {
  deleteTrainingOption,
  generateDummyCertificateTemplate,
} from "../../../../appRedux/actions";
import TrainingOptionActions from "../../../../components/adminRole/trainingOption/TrainingOptionActions";

export default function TrainingOptionActionsContainer({ trainingOptionId, subpath }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleEdit = () => {
    history.push("/training-option/" + trainingOptionId + "/edit");
  };
  const handleDelete = () => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin menghapus pilihan training ini ?",
      onOk: () =>
        dispatch(deleteTrainingOption(trainingOptionId)).then(() =>
          history.push("/training-option/")
        ),
    });
  };
  const handleDownloadDummyCertificate = () => {
    dispatch(generateDummyCertificateTemplate(trainingOptionId)).then((url) =>
      window.open(url, "_blank")
    );
  };
  return (
    <TrainingOptionActions
      onEdit={handleEdit}
      onDelete={handleDelete}
      onDownloadDummyCertificate={handleDownloadDummyCertificate}
      subpath={subpath}
      trainingOptionId={trainingOptionId}
    />
  );
}
