import { PageHeader, Card, Space, List, Modal, Button } from "antd"
import { Link } from "react-router-dom"

export default function JadwalPage() {
  const data = [
    {
      title: "Informasi Jadwal",
      description: "Tampilan text di bagian atas di page jadwal.idn.id",
      path: "/jadwal/information",
    },
    {
      title: "Tahu ID-Networkers",
      description: "Pilihan untuk peserta tahu ID-Networkers dari mana saja",
      path: "/configurations/know_from_where",
    },
    {
      title: "Undangan Email",
      description: "Format undangan training yang dikirimkan melalui email",
      path: "/configurations/invitations/email",
    },
    {
      title: "Undangan Whatsapp",
      description: "Format undangan training yang dikirimkan melalui whatsapp",
      path: "/configurations/invitations/whatsapp",
    },
    {
      title: "Peraturan Training",
      description: "Isi peraturan training yang digunakan ketika akan daftar training",
      path: "/configurations/training_rules",
    },
    {
      title: "Pesan Affiliator",
      description: "Pesan yang ditampilkan pada halaman Program Affiliasi untuk peserta yang terdaftar program affiliate",
      path: "/configurations/activated_affiliate_message",
    },
    {
      title: "Pesan Non Affiliator",
      description: "Pesan yang ditampilkan pada halaman Program Affiliasi untuk peserta yang belum terdaftar program affiliate",
      path: "/configurations/deactivated_affiliate_message",
    },
  ];

  return <>
    <PageHeader
      ghost={false}
      title="Konfigurasi"
    />

    <Card bordered={false} style={{ borderRadius: 0 }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <List
          bordered
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              actions={[<Link key="list-loadmore-edit" to={item.path}>edit</Link>]}
            >
              <List.Item.Meta
                avatar={<></>}
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </Space>
    </Card>
  </>
}
